import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare var webkitSpeechRecognition: any;

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css'],
})
export class ConversationComponent {
  recognition: any;
  transcript: string = '';
  isListening: boolean = false;
  apiUrl: string = 'https://api.listen-beta.rozie.ai/processTS';
  apiKey: string = '6cVN3QsjMiS5kAhsO7v368nTEYqjTEk9HevbO0Ec';
  showNotification: boolean = false;
  isSuccess: boolean = false;
  notificationMessage: string = '';
  constructor(private http: HttpClient) {
    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
  }

  startListening(): void {
    this.recognition.start();
    this.isListening = true;

    this.recognition.onresult = (event: any) => {
      const transcript = event.results[event.results.length - 1][0].transcript;
      this.transcript += transcript;
    };
  }

  stopListening(): void {
    this.recognition.stop();
    this.isListening = false;

    // Post the transcript to the API
    this.http
      .post(
        this.apiUrl,
        { transcript: this.transcript, emails: ['samar1066@gmail.com'] },
        {
          headers: {
            'x-api-key': this.apiKey,
            'Content-Type': 'application/json',
          },
        }
      )
      .subscribe(
        (response: any) => {
          setTimeout(() => {
            this.transcript = '';
          }, 3000); // Duration in milliseconds (3 seconds)
          console.log('Transcript posted successfully:', response);
          // Show success notification
          this.showSuccessNotification('Transcript processed successfully');
        },
        (error: any) => {
          setTimeout(() => {
            this.transcript = '';
          }, 3000); // Duration in milliseconds (3 seconds)

          console.error('Error posting transcript:', error);
          // Show error notification
          this.showErrorNotification('Error processing transcript');
        }
      );
  }

  showSuccessNotification(message: string): void {
    this.showNotification = true;
    this.isSuccess = true;
    this.notificationMessage = message;

    setTimeout(() => {
      this.showNotification = false;
      this.notificationMessage = '';
    }, 6000); // Duration in milliseconds (3 seconds)
  }

  showErrorNotification(message: string): void {
    this.showNotification = true;
    this.isSuccess = false;
    this.notificationMessage = message;

    setTimeout(() => {
      this.showNotification = false;
      this.notificationMessage = '';
    }, 6000); // Duration in milliseconds (3 seconds)
  }
}
