<div class="container">
  <h1>Let me summarize it for you </h1>
  <button (click)="startListening()" [disabled]="isListening">
    Start Listening
  </button>
  <button (click)="stopListening()" [disabled]="!isListening">
    Stop Listening
  </button>
  <p>{{ transcript }}</p>
</div>
<div
  class="notification"
  [ngClass]="{ show: showNotification, success: isSuccess }"
>
  {{ notificationMessage }}
</div>
